<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3zm5 15h-2v-6H9v6H7v-7.81l5-4.5l5 4.5V18z"
      fill="currentColor"
    />
    <path
      opacity=".3"
      d="M7 10.19V18h2v-6h6v6h2v-7.81l-5-4.5z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IcTwotoneHome",
};
</script>
